import React, { useState } from "react";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

function BulkUpdate() {
  const [jsonData, setJsonData] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonResult = XLSX.utils.sheet_to_json(sheet);

      setJsonData(jsonResult);

      // Send the JSON data to the server
      fetch("https://askvital.onrender.com/api/upload-excel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonResult),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Log the response from the server
          // Handle the response as needed
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };

  return (
    <div className="m-5">
      <h2>Excel to JSON Converter</h2>
      <div className="m-5" {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop an Excel file here, or click to select one</p>
      </div>
      {jsonData && (
        <div>
          <h3>JSON Data:</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(jsonData[0]).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{JSON.stringify(jsonData[0][key])}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default BulkUpdate;
