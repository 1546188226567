import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import AdminLogin from "./pages/AdminLogin";
import NotFoundComponent from "./pages/NotFoundComponent";
import Home from "./components/Home";
import Users from "./components/Users";
import BulkUpdate from "./components/BulkUpdate";
import AdminCategories from "./components/NewCategories";
import Notifications from "./components/Notifications";
import ReviewsEdit from "./components/ReviewsEdit";
import AdminHospitals01 from "./components/AdminHospital01";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider, useAuth } from "./components/AuthContext"; // Import the useAuth hook
import Faq from "./components/Faq";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<AdminLogin />} />
          {isAuthenticated && (
            <Route element={<PrivateRoute />}>
              <Route path="/AdminHome" element={<Home />} />
              <Route path="/customers" element={<Users />} />
              <Route path="/AdminHospitals" element={<AdminHospitals01 />} />
              <Route path="/bulkupdate" element={<BulkUpdate />} />
              <Route path="/AdminCategories" element={<AdminCategories />} />
              <Route path="/notification" element={<Notifications />} />
              <Route path="/reviews-edit" element={<ReviewsEdit />} />
              <Route path="/faq" element={<Faq />} />
            </Route>
          )}
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
