import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = async (formData, setError) => {
    try {
      const response = await fetch(
        "https://askvital.onrender.com/api/admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        if (data.message === "Admin authenticated successfully") {
          setIsAuthenticated(true);
        } else {
          setError("Authentication failed. Please check your credentials.");
        }
      } else if (response.status === 401) {
        setError("Invalid credentials or not authorized as an admin.");
      } else {
        setError(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
