import React from "react";
import "../styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { useAuth } from "../components/AuthContext";

function Navbar() {
  const navigate = useNavigate(); // Use useNavigate hook
  const { isAuthenticated, login, logout } = useAuth();

  const handleLogout = () => {
    //   // Perform logout actions here (e.g., clearing user session or cookies)
    //   // Replace this with your actual logout logic

    // After logout, redirect to the "/" page
    logout();
    navigate("/");
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img
            src="https://i.ibb.co/5c5X9f1/oie-Zhohzh-ISr2s-H.png"
            className="admin-Logo"
            alt="askvital-logo-img"
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-ul navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/AdminHome"
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AdminHospitals">
                  Hospitals
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/notification">
                  Notifications
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/AdminCategories">
                  Categories
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/customers">
                  Customers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/reviews-edit">
                  Review
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faq">
                  Faq
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/bulkupdate">
                  Bulk Add
                </Link>
              </li>
            </ul>
            <div className="header-right">
              <BsPersonCircle
                className="icon"
                onClick={handleLogout}
                text="logout"
              />
            </div>
          </div>
        </div>
      </nav>
      <hr />
    </div>
  );
}

export default Navbar;
