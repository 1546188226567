import React, { useState, useEffect } from "react";
import axios from "axios";

const API_BASE_URL = "https://askvital.onrender.com/api/healthcare";

function Faq() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedHealthcareEntityName, setSelectedHealthcareEntityName] =
    useState("");
  const [healthcareData, setHealthcareData] = useState([]);
  const [showFAQs, setShowFAQs] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedFAQ, setEditedFAQ] = useState({
    id: "",
    question: "",
    answer: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isAdmin] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        const data = response.data;

        const categories = Array.from(
          new Set(data.map((item) => item.categories))
        );

        setCategoryOptions(categories);
        setHealthcareData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
    setSelectedHealthcareEntityName("");
    setShowFAQs(!!selected);
    setIsEditing(false);
    setEditedFAQ({ id: "", question: "", answer: "" });
  };

  const handleEdit = (faqId, faqQuestion, faqAnswer, faqName) => {
    setEditedFAQ({ id: faqId, question: faqQuestion, answer: faqAnswer });
    setIsEditing(true);
    setSelectedHealthcareEntityName(faqName);
  };

  const handleSave = async (faqId) => {
    try {
      const updatedFAQ = {
        question: editedFAQ.question,
        answer: editedFAQ.answer,
      };

      const url = `${API_BASE_URL}/${selectedHealthcareEntityName}/faq/${faqId}`;

      await axios.put(url, updatedFAQ);

      setHealthcareData((prevData) =>
        prevData.map((healthcareItem) =>
          healthcareItem.name === selectedHealthcareEntityName
            ? {
                ...healthcareItem,
                faq: healthcareItem.faq.map((faq) =>
                  faq._id === faqId ? { ...faq, ...updatedFAQ } : faq
                ),
              }
            : healthcareItem
        )
      );

      setIsEditing(false);
      setEditedFAQ({ id: "", question: "", answer: "" });
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  const handleDelete = async (faqId) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/${selectedHealthcareEntityName}/faq/${faqId}`
      );

      setHealthcareData((prevData) =>
        prevData.map((healthcareItem) =>
          healthcareItem.name === selectedHealthcareEntityName
            ? {
                ...healthcareItem,
                faq: healthcareItem.faq.filter((faq) => faq._id !== faqId),
              }
            : healthcareItem
        )
      );

      console.log("FAQ deleted successfully!");
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      console.error("An error occurred while deleting the FAQ.");
    }
  };

  return (
    <div className="m-5">
      <h2>FAQs</h2>
      <label htmlFor="categorySelect">Select a Category: </label>
      <select
        id="categorySelect"
        value={selectedCategory}
        onChange={handleCategoryChange}
      >
        <option value={null}>Select Category</option>
        {categoryOptions.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>

      {showFAQs && (
        <div>
          <h3>FAQs for {selectedCategory}</h3>
          {healthcareData
            .filter((item) => item.categories === selectedCategory)
            .map((selectedFAQ) => (
              <div key={selectedFAQ._id}>
                <h4>{selectedFAQ.name}</h4>
                {selectedFAQ.faq && selectedFAQ.faq.length > 0 ? (
                  <table>
                    <thead className="bg-dark">
                      <tr>
                        <th className="text-dark">FAQ Question</th>
                        {isAdmin && <th className="text-dark">Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {selectedFAQ.faq.map((faq) => (
                        <tr key={faq._id}>
                          <td>
                            {isEditing && editedFAQ.id === faq._id ? (
                              <div>
                                <input
                                  type="text"
                                  value={editedFAQ.question}
                                  onChange={(e) =>
                                    setEditedFAQ({
                                      ...editedFAQ,
                                      question: e.target.value,
                                    })
                                  }
                                />
                                <input
                                  type="text"
                                  value={editedFAQ.answer}
                                  onChange={(e) =>
                                    setEditedFAQ({
                                      ...editedFAQ,
                                      answer: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            ) : (
                              // Render the question only when not editing
                              faq.question
                            )}
                          </td>
                          {isAdmin && (
                            <td>
                              {isEditing && editedFAQ.id === faq._id ? (
                                <button onClick={() => handleSave(faq._id)}>
                                  Save
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    handleEdit(
                                      faq._id,
                                      faq.question,
                                      faq.answer,
                                      selectedFAQ.name
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              )}
                              <button onClick={() => handleDelete(faq._id)}>
                                Delete FAQ
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No FAQs available for {selectedFAQ.name}</p>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Faq;
