import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import "../styles/AdminLogin.css";

function AdminLogin() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAdminLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try {
      await login(formData, setError);
      navigate("/AdminHome");
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="container020">
      <img
        src="https://i.ibb.co/5c5X9f1/oie-Zhohzh-ISr2s-H.png"
        className="Logo m-2"
        alt="Logo"
      />
      <form className="Adminform mb-5" onSubmit={handleAdminLogin}>
        <div>
          <label className="labelAdmin" htmlFor="username">
            Username
          </label>
          <input
            type="text"
            id="username"
            className="AdminInputText"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label className="labelAdmin" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="AdminInputpassword"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button className="AdminLoginbtn" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}

export default AdminLogin;
