import React, { useState, useEffect } from "react";
import axios from "axios";

const API_BASE_URL = "https://askvital.onrender.com/api/healthcare";

function ReviewsEdit() {
  const [selectedReview, setSelectedReview] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [showReviews, setShowReviews] = useState(false);
  const [nameOptions, setNameOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedReview, setEditedReview] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isAdmin] = useState(true); // Replace with your authentication logic

  // Additional state for edited ratings
  const [editedRatings, setEditedRatings] = useState({
    qualityRating: "",
    compassionRating: "",
    cleanlinessRating: "",
    efficiencyRating: "",
    costRange: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        const data = response.data;

        const names = data.map((item) => item.name);

        setNameOptions(names);
        setReviews(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNameChange = (e) => {
    const selected = e.target.value;
    setSelectedName(selected);

    const selectedReview = reviews.find((item) => item.name === selected);

    if (selectedReview) {
      setEditedReview("");
      setShowReviews(true);
    } else {
      setEditedReview("");
      setShowReviews(false);
    }

    setIsEditing(false);
  };

  const handleEdit = (review) => {
    setSelectedReview(review);
    setEditedReview(review.review);
    setEditedRatings({
      qualityRating: review.qualityRating,
      compassionRating: review.compassionRating,
      cleanlinessRating: review.cleanlinessRating,
      efficiencyRating: review.efficiencyRating,
      costRange: review.costRange,
    });
    setIsEditing(true);
  };

  const handleSave = async (reviewId) => {
    try {
      const updatedReview = {
        ...selectedReview,
        review: editedReview,
        ...editedRatings,
      };

      console.log("Updated Review:", updatedReview);

      await axios.put(
        `${API_BASE_URL}/${selectedName}/reviews/${reviewId}`,
        updatedReview
      );

      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.name === selectedName
            ? {
                ...review,
                reviews: review.reviews.map((r) =>
                  r._id === reviewId ? updatedReview : r
                ),
              }
            : review
        )
      );

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating review:", error);
    }
  };

  const handleDelete = async (reviewId) => {
    try {
      console.log("Attempting to delete review with ID:", reviewId);

      if (!selectedName) {
        console.error("Selected name is null or undefined.");
        return;
      }

      console.log("Deleting review with ID:", reviewId);

      const response = await axios.delete(
        `${API_BASE_URL}/${selectedName}/reviews/${reviewId}`
      );

      if (response.status !== 200) {
        console.error(
          "Error deleting review. Server responded with:",
          response.status,
          response.statusText
        );
        return;
      }

      console.log("Review deleted from the server", reviewId);

      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.name === selectedName
            ? {
                ...review,
                reviews: review.reviews.filter((r) => r._id !== reviewId),
              }
            : review
        )
      );
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  return (
    <div className="m-5">
      <h2>Reviews</h2>
      <label htmlFor="nameSelect">Select a Name: </label>
      <select id="nameSelect" value={selectedName} onChange={handleNameChange}>
        <option value={null}>Select Name</option>
        {nameOptions.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>

      {showReviews && (
        <div>
          <h3>Review for {selectedName}</h3>
          {isEditing ? (
            <div>
              <table>
                <thead className="bg-dark">
                  <tr>
                    <th className="text-dark">Quality Rating</th>
                    <th className="text-dark">Compassion Rating</th>
                    <th className="text-dark">Cleanliness Rating</th>
                    <th className="text-dark">Efficiency Rating</th>
                    <th className="text-dark">Cost Range</th>
                    <th className="text-dark">Review</th>
                    {isAdmin && <th className="text-dark">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {reviews
                    .filter((item) => item.name === selectedName)
                    .map((selectedReview) =>
                      selectedReview.reviews.map((review) => (
                        <tr key={review._id}>
                          <td>
                            <select
                              value={editedRatings.qualityRating}
                              onChange={(e) =>
                                setEditedRatings({
                                  ...editedRatings,
                                  qualityRating: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          <td>
                            <select
                              value={editedRatings.compassionRating}
                              onChange={(e) =>
                                setEditedRatings({
                                  ...editedRatings,
                                  compassionRating: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>

                          <td>
                            <select
                              value={editedRatings.cleanlinessRating}
                              onChange={(e) =>
                                setEditedRatings({
                                  ...editedRatings,
                                  cleanlinessRating: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          <td>
                            <select
                              value={editedRatings.efficiencyRating}
                              onChange={(e) =>
                                setEditedRatings({
                                  ...editedRatings,
                                  efficiencyRating: e.target.value,
                                })
                              }
                            >
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          <td>
                            <select
                              value={editedRatings.costRange}
                              onChange={(e) =>
                                setEditedRatings({
                                  ...editedRatings,
                                  costRange: e.target.value,
                                })
                              }
                            >
                              <option value="0-1000">0-1000</option>
                              <option value="1000-10000">1000-10000</option>
                              <option value="10000-100000">10000-100000</option>
                            </select>
                          </td>

                          <td>
                            <textarea
                              value={editedReview}
                              onChange={(e) => setEditedReview(e.target.value)}
                            ></textarea>
                          </td>
                          {isAdmin && (
                            <td>
                              <button onClick={() => handleSave(review._id)}>
                                Save
                              </button>
                              <button onClick={() => handleDelete(review._id)}>
                                Delete Review
                              </button>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <table>
                <thead>
                  <tr>
                    <th className="text-dark">Quality Rating</th>
                    <th className="text-dark">Compassion Rating</th>
                    <th className="text-dark">Cleanliness Rating</th>
                    <th className="text-dark">Efficiency Rating</th>
                    <th className="text-dark">Cost Range</th>
                    <th className="text-dark">Review</th>
                    {isAdmin && <th className="text-dark">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {reviews
                    .filter((item) => item.name === selectedName)
                    .map((selectedReview) =>
                      selectedReview.reviews.map((review) => (
                        <tr key={review._id}>
                          <td>{review.qualityRating}</td>
                          <td>{review.compassionRating}</td>
                          <td>{review.cleanlinessRating}</td>
                          <td>{review.efficiencyRating}</td>
                          <td>{review.costRange}</td>
                          <td>{review.review}</td>
                          {isAdmin && (
                            <td>
                              <button onClick={() => handleEdit(review)}>
                                Edit
                              </button>
                              <button onClick={() => handleDelete(review._id)}>
                                Delete Review
                              </button>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ReviewsEdit;
