import React, { useState, useEffect } from "react";
import "../styles/admindashbord.css";
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
  BsReverseLayoutTextSidebarReverse,
} from "react-icons/bs";

function Home() {
  const [hospitalCount, setHospitalCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  useEffect(() => {
    fetch("https://askvital.onrender.com/api/healthcare")
      .then((response) => response.json())
      .then((data) => {
        const count = data.length;
        setHospitalCount(count);
      })
      .catch((error) => {
        console.error("Error fetching hospital count:", error);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://askvital.onrender.com/api/business/admin/pending-submissions"
    )
      .then((response) => response.json())
      .then((data) => {
        const alertCount = data.length;
        setAlertCount(alertCount);
      })
      .catch((error) => {
        console.error("Error fetching alert count:", error);
      });
  }, []);

  useEffect(() => {
    fetch("https://askvital.onrender.com/api/user/admin-route")
      .then((response) => response.json())
      .then((data) => {
        const userCount = data.length;
        setUserCount(userCount);
      })
      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  }, []);

  // useEffect(() => {
  //   fetch("https://askvital.onrender.com/api/healthcare/reviews")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const reviewCount = data.reduce(
  //         (acc, hospital) => acc + hospital.reviews.length,
  //         0
  //       );
  //       setReviewCount(reviewCount);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching review count:", error);
  //     });
  // }, []);

  return (
    <main className="Home-Conatiner m-5">
      <div className="main-title">
        <h3>DASHBOARD</h3>
      </div>

      <div className="main-cards">
        <div className="card" id="card01">
          <div className="card-inner">
            <h3>Hospitals</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1>{hospitalCount}</h1>
        </div>
        <div className="card" id="card02">
          <div className="card-inner">
            <h3>Alerts</h3>
            <BsFillBellFill className="card_icon" />
          </div>
          <h1>{alertCount}</h1>
        </div>
        <div className="card" id="card03">
          <div className="card-inner">
            <h3>Categories</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1>4</h1>
        </div>
        <div className="card" id="card04">
          <div className="card-inner">
            <h3>Customers</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1>{userCount}</h1>
        </div>
        {/* <div className="card" id="card05">
          <div className="card-inner">
            <h3>Reviews</h3>
            <BsReverseLayoutTextSidebarReverse className="card_icon" />
          </div>
          <h1>{reviewCount}</h1>
        </div> */}
      </div>
    </main>
  );
}

export default Home;
