import React, { useState, useEffect } from "react";
import axios from "axios";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Fetch pending submissions from the server
    axios
      .get(
        "https://askvital.onrender.com/api/business/admin/pending-submissions"
      )
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);

  const handleApprove = (notification) => {
    // Send a request to approve the submission
    axios
      .post("https://askvital.onrender.com/api/healthcare", {
        submissionId: notification._id,
        status: "Approved",
        notificationId: notification._id,
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage("Submission approved successfully");
          removeNotification(notification._id);
        } else {
          console.error("Error approving submission:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error approving submission:", error);
      });
  };

  const handleDeny = (notification) => {
    // Send a request to deny the submission
    axios
      .post(
        "https://askvital.onrender.com/api/business/admin/update-submission-status",
        {
          submissionId: notification._id,
          status: "Denied",
          notificationId: notification._id,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setMessage("Submission denied successfully");
          removeNotification(notification._id);
        } else {
          console.error("Error denying submission:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error denying submission:", error);
      });
  };

  const removeNotification = (submissionId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter(
        (notification) => notification._id !== submissionId
      )
    );
  };

  return (
    <div className="m-5">
      <h2>Notification Section</h2>
      {message && <div>{message}</div>}
      <table>
        <thead>
          <tr className="text-dark">
            <th>Name</th>
            <th>City</th>
            <th>Categories</th>
            <th>Timings</th>
            <th>Contact Number</th>
            <th>About</th>
            <th>Speciality</th>
            <th>Email ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => (
            <tr key={notification._id}>
              <td>{notification.name}</td>
              <td>{notification.city}</td>
              <td>{notification.categories}</td>
              <td>{notification.timings}</td>
              <td>{notification.contactnumber}</td>
              <td>{notification.about}</td>
              <td>{notification.speciality}</td>
              <td>{notification.emailId}</td>
              <td>{notification.speciality}</td>
              <td>
                <button onClick={() => handleApprove(notification)}>
                  Approve
                </button>
                <button onClick={() => handleDeny(notification)}>Deny</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Notifications;
